import { useEffect } from "react";

export default function TermOfUse() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'Terms and Conditions';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Read the Terms and Conditions of Snake Game. Learn about your rights and responsibilities when using our website and services. Stay informed with our policies.");
        }
        return () => {
            document.title = 'Snake - Play Snake Game Online Unblocked🐍 PLAY NOW!';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Take on the Snake Game challenge, an arcade classic that improves focus and reflexes. Play Snake Computer Game online or offline and enjoy hours of addictive, skill-based fun!');
            }
        };
    }, []);

    return (
        <div className="content-body min-h-[calc(100vh-390px)] text-white">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        Terms and Conditions
                    </h1>
                    <br />
                    <span>
                        Welcome to the Snake Game! By accessing or using our website and services, you agree to comply with and be bound by the following Terms and Conditions. Please read them carefully.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        1. Acceptance of Terms
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        By accessing this website, you agree to these Terms and Conditions. If you do not agree to any part of these terms, you should discontinue the use of our services immediately.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        2. Use of Our Website
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        You agree to use the website in a manner that complies with all applicable laws and regulations. You are prohibited from using the site for any unlawful purpose or in a way that may harm our services or other users.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        3. Intellectual Property
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        All content, including text, graphics, logos, and software, is the property of Snake Game or its licensors. You may not reproduce, distribute, or create derivative works without explicit written permission.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        4. Limitation of Liability
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        We strive to ensure that the website operates smoothly and securely. However, we do not guarantee uninterrupted access and are not liable for any damages arising from the use of the website.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        5. Changes to the Terms
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        We reserve the right to modify these Terms and Conditions at any time. Please review this page periodically for updates. Your continued use of the site after any changes indicates acceptance of the new terms.
                    </div>
                </div>
            </div>
        </div>
    )
}