import { useEffect } from "react";

export default function ContactUs() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'Contact Us - Snake Game';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Get in touch with the Snake Game team! Contact us for support, feedback, or inquiries. We're here to help you with any questions related to our game.");
        }
        return () => {
            document.title = 'Snake - Play Snake Game Online Unblocked🐍 PLAY NOW!';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Take on the Snake Game challenge, an arcade classic that improves focus and reflexes. Play Snake Computer Game online or offline and enjoy hours of addictive, skill-based fun!');
            }
        };
    }, []);

    return (
        <div className="content-body min-h-[calc(100vh-390px)] text-white">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">Contact Us</h1>
                    <br />
                    <span>
                        We value your feedback and are here to assist you with any questions or concerns regarding Snake Game. Please don’t hesitate to reach out to us using the contact information below.
                    </span>
                    <br />
                    <br />
                    <h2>
                        <strong>Contact Information</strong>
                    </h2>
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                <strong>Email: </strong>
                                <a href="mailto:support@snakegame.games" className="ml-2 text-blue-500">{`[support@snakegame.games]`}</a>
                            </li>
                        </ul>
                    </div>
                    <br/>
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        If you have any technical issues, suggestions, or need help with anything related to our game, feel free to get in touch. We aim to respond to all inquiries within 24-48 hours.
                    </div>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        You can also follow us on social media for the latest updates and news about Snake Game!
                    </div>
                </div>
            </div>
        </div>
    )
}