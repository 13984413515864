import { useEffect, useState } from "react";
// import Popular from "./popular/page";

function useMobilePlatform() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobilePlatforms = /android|iphone|ipod|windows phone|blackberry/i;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) || 
                  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    
    setIsMobile(mobilePlatforms.test(userAgent) || isIOS);
  }, []);

  return isMobile;
}

export default function Home() {
  const isMobile = useMobilePlatform();

  const handleFullScreen = () => {
    const iframe = document.getElementById("game-iframe");
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  };

  return (
    <div className="relative z-50">
      {!isMobile ? (
        <div className="2xl:flex lg:flex flex items-center justify-center 2xl:h-[85vh] lg:h-[75vh] h-[65vh] lg:mx-24 mx-8">
          <iframe
            id="game-iframe"
            src="https://snake.gamestores.fun/"
            width="100%"
            height="100%"
            frameBorder="0"
            title="Snake Game"
            style={{
              aspectRatio: "16 / 9",
              objectFit: "cover",
              borderRadius: "8px",
              maxWidth: "fit-content",
              border: "2px solid #0044FF",
            }}
          ></iframe>
        </div>
      ) : (
        <div className="flex items-center justify-center 2xl:h-[85vh] lg:h-[65vh] h-[55vh] lg:mx-24 mx-8 bg-slate-500 opacity-90 rounded-lg">
          <span className="text-white text-center poppins-regular md:text-lg text-sm">
            This game is not available on mobile web.
            <br /> Please play it on your computer.
          </span>
        </div>
      )}
      <div className="flex items-center justify-center">
        <div class="container">
          <div className="2xl:flex lg:flex hidden justify-center mt-6 2xl:mb-[80px] lg:mb-[48px] mb-[48px]">
            <button
              onClick={handleFullScreen}
              className="btn-full-screen 2xl:py-6 2xl:px-16 lg:py-3 lg:px-8 md:py-3 md:px-8 px-7 py-3 rounded"
            >
              <span className="lilita-one-regular 2xl:text-4xl lg:text-3xl md:text-3xl text-white">
                Full Screen
              </span>
            </button>
          </div>
          {/* <Popular /> */}

          <div class=" text-white 2xl:px-20 lg:px-20 px-6 2xl:mt-20 lg:mt-20 mt-1">
            <br />
            <br />
            <div class="2xl:text-lg lg:text-[14px] text-[14px] poppins-regular">
              Snake Game is a classic video game that has captivated players for
              decades. First appearing in arcades and computers in the late
              1970s, the game became a household name when it was pre-installed
              on Nokia phones in the late 1990s. The simplicity and
              addictiveness of Snake Game made it one of the most popular mobile
              games of its time.
            </div>
            <br />
            <h2 class="lilita-one-regular 2xl:text-2xl lg:text-base text-base">
              The History and Development of Snake Game:
            </h2>
            <br />
            <div class="2xl:text-lg lg:text-[14px] text-[14px] poppins-regular">
              The concept of Snake Game is rooted in early computer and arcade
              games. The first recognizable version of Snake appeared in 1976 as
              "Blockade," a two-player arcade game developed by Gremlin. Over
              the years, variations of Snake appeared on different platforms,
              but it wasn’t until Nokia included the game in its mobile phones
              in 1997 that Snake gained widespread recognition. Millions of
              people worldwide played Snake on their Nokia devices, making it a
              significant part of mobile gaming history.
              <br />
              <br />
              Since then, Snake has been developed into many different versions
              and adaptations, ranging from simple 2D graphics to more complex
              3D versions. Despite these developments, the core gameplay remains
              largely the same, proving that the original formula has stood the
              test of time.
            </div>
            <br />
            <h2 class="lilita-one-regular 2xl:text-2xl lg:text-base text-base">
              How to Play Snake Game
            </h2>
            <br />
            <div class="2xl:text-lg lg:text-[14px] text-[14px] poppins-regular">
              The gameplay of Snake Game is simple yet challenging. Players
              control a snake that moves continuously across the screen, aiming
              to eat objects (often represented as dots or squares). Each time
              the snake consumes an object, it grows longer. The challenge
              increases as the snake’s body becomes longer, making it harder to
              navigate without crashing into its own tail or the edges of the
              screen. The game ends when the snake crashes into a wall or its
              own body.
            </div>
            <br />
            <h2 class="lilita-one-regular 2xl:text-2xl lg:text-base text-base">
              Why Snake Game is Still Popular
            </h2>
            <br />
            <div class="2xl:text-lg lg:text-[14px] text-[14px] poppins-regular">
              One of the main reasons Snake Game remains popular today is its
              simplicity. It’s easy to understand, doesn’t require complicated
              controls, and provides an immediate challenge that can be enjoyed
              by people of all ages. Snake also taps into the nostalgia of those
              who grew up playing the game on their first mobile phones or early
              computer systems. With the rise of retro gaming, Snake has seen a
              resurgence in popularity, with many modern versions and remakes
              available on smartphones, websites, and even gaming consoles.
            </div>
            <br />
            <h2 class="lilita-one-regular 2xl:text-2xl lg:text-base text-base">
              Modern Adaptations of Snake Game
            </h2>
            <br />
            <div class="2xl:text-lg lg:text-[14px] text-[14px] poppins-regular">
              In recent years, Snake Game has evolved to include more modern
              features. Developers have introduced new mechanics, multiplayer
              modes, and enhanced graphics. For example, some versions now allow
              players to compete against others online, creating a competitive
              environment that was not present in the original game. However,
              the essence of Snake - growing your snake without crashing—remains
              the key to its enduring appeal.
              <br />
              <br />
              Snake Game is a timeless classic that continues to entertain both
              new and experienced players. It's simple yet addictive gameplay,
              combined with a sense of nostalgia, has made it a staple in the
              gaming world. Whether you’re playing a basic version on an old
              Nokia phone or a modern adaptation on your smartphone, Snake
              remains a fun and challenging experience.
            </div>
          </div>

          <div class="control flex items-center 2xl:mt-10 lg:mt-10 mt-4 2xl:mx-20 lg:mx-20 mx-6">
            <div className="2xl:pl-8 2xl:py-8 lg:pl-8 lg:py-8 px-8 py-8">
              <h2 className="2xl:text-2xl lg:text-base text-[14px] font-bold text-start lilita-one-regular">
                Control - How to play
              </h2>
              <ul
                style={{
                  listStyleType: "disc",
                  marginLeft: "30px",
                }}
              >
                <li className="2xl:text-lg lg:text-[14px] text-[12px] text-start mt-4 poppins-regular">
                  Change Direction: Mouse or WASD Keys or Arrow Keys
                </li>
                <li className="2xl:text-lg lg:text-[14px] text-[12px] text-start poppins-regular">
                  Speed Boost: Hold Left Mouse Click or Spacebar
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
