import { useEffect } from "react";

export default function Policy() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'Privacy Policy';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Snake Game Privacy Policy explains how we collect, use, and protect your personal data. Learn about our commitment to safeguarding your privacy online.");
        }
        return () => {
            document.title = 'Snake - Play Snake Game Online Unblocked🐍 PLAY NOW!';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Take on the Snake Game challenge, an arcade classic that improves focus and reflexes. Play Snake Computer Game online or offline and enjoy hours of addictive, skill-based fun!');
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-460px)] lg:min-h-[calc(100vh-390px)] text-white">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                <h1 className="text-3xl font-bold text-center">
                        Privacy Policy
                    </h1>
                    <br />
                    <span>
                        At Snake Game, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        1. Information We Collect
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                <strong>Personal Information:</strong> When you contact us or register for an account, we may collect personal details such as your name, email address, and phone number.
                            </li>
                            <li>
                                <strong>Usage Data:</strong> We may collect information about how you interact with our website, including IP addresses, browser type, and pages visited, to improve our services.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        2. How We Use Your Information
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                To provide and improve our services.
                            </li>
                            <li>
                                To respond to your inquiries and provide customer support.
                            </li>
                            <li>
                                To send you updates, newsletters, or promotional content, if you’ve opted in.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        3. Data Security
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        We implement various security measures to protect your personal information from unauthorized access, alteration, or disclosure. However, no method of transmission over the internet is entirely secure, and we cannot guarantee absolute security.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        4. Sharing Your Information
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        We do not sell, trade, or rent your personal information to third parties. However, we may share data with trusted service providers who assist us in operating our website or conducting our business, provided they agree to keep your information confidential.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        5. Cookies
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        Our website may use cookies to enhance your browsing experience. You have the option to accept or decline cookies through your browser settings. Declining cookies may affect certain functionalities of the site.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        6. Changes to the Privacy Policy
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        We reserve the right to update this Privacy Policy at any time. Please check this page regularly for updates. Your continued use of our services constitutes acceptance of any changes.
                    </div>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        If you have any questions or concerns regarding our Privacy Policy, please contact us at <a href="mailto:support@snakegame.games" className=" text-blue-500">{`[support@snakegame.games]`}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}