import { useEffect } from "react";

export default function About() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'About us - Snake Game';
        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', 'Classic Snake Game: Discover the history and evolution of the timeless Snake Game, from its origins in arcades to its rise to fame on Nokia phones. Play modern versions today!');
        }
        return () => {
            document.title = 'Snake - Play Snake Game Online Unblocked🐍 PLAY NOW!';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Take on the Snake Game challenge, an arcade classic that improves focus and reflexes. Play Snake Computer Game online or offline and enjoy hours of addictive, skill-based fun!');
            }
        };
    }, []);
    return (
        <div className="content-body min-h-[calc(100vh-460px)] text-white">
            <div className="flex items-center justify-center">
                <div className="container 2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">Snake Game: A Timeless Classic in Gaming History</h1>
                    <br />
                    <span>
                        <strong>Snake Game</strong> is a retro arcade-style video game that has stood the test of time, captivating players for decades. Known for its simplicity and addictive gameplay, Snake has evolved through the years, gaining popularity across various platforms. In this page, we’ll explore the history, development, and gameplay of <strong>Snake Game</strong>, and why it continues to be loved by both nostalgic players and new generations.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        What is Snake Game?
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        Snake Game is a video game where players control a growing snake, navigating a confined space to collect food while avoiding obstacles. The snake grows longer with each food item consumed, making the game increasingly challenging. The objective is simple: survive as long as possible without colliding with the snake’s own body or the game’s borders.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        The History of Snake Game
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        Snake Game originated in the late 1970s, with its first appearance as "Blockade," an arcade game developed by Gremlin. The concept of controlling a line or snake-like character that grows with each action has been adapted and modified by numerous developers over the years. However, it was Nokia’s decision to pre-install Snake on their mobile phones in 1997 that made the game a global phenomenon.
                        <br />
                        <br />
                        Millions of Nokia users worldwide played Snake, leading to its widespread recognition as one of the most iconic mobile games ever. Its minimalist design and challenging gameplay were perfect for early mobile phones, which had limited graphics capabilities.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        How to Play Snake Game
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        The rules of <strong>Snake Game</strong> are straightforward:
                        <br />
                        <ul className="numbered-list">
                            <li>
                                Players control a snake that moves continuously in one direction.
                            </li>
                            <li>
                                The snake must eat food (typically dots or blocks) scattered across the playing field.
                            </li>
                            <li>
                                Every time the snake eats, it grows longer, making it more difficult to avoid collisions.
                            </li>
                            <li>
                                The game ends when the snake crashes into its own body or the game boundaries.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        The goal is to achieve the highest score by collecting as much food as possible without crashing. As the snake grows longer, maneuvering becomes more difficult, increasing the challenge and excitement of the game.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        Modern Versions and Adaptations
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        Snake Game has seen numerous adaptations since its humble beginnings. While the original game was 2D and featured basic graphics, modern versions have introduced 3D elements, power-ups, and multiplayer modes. Today, Snake is available on mobile apps, gaming consoles, and online platforms, with many developers adding new twists to the classic gameplay.
                    </div>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        Popular modern adaptations include:
                        <br />
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                <strong>Slither.io:</strong> A multiplayer online version where players compete against others worldwide, trying to create the largest snake by consuming smaller opponents.
                            </li>
                            <li>
                                <strong>Snake Rivals:</strong> A mobile game that introduces colorful graphics, new game modes, and competitive online play.
                            </li>
                            <li>
                                <strong>Classic Snake:</strong> Remakes of the original Nokia Snake that bring back the nostalgia of playing on old mobile phones.
                            </li>
                        </ul>
                        <br />
                        <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                            Despite the updates and new features, the core of Snake Game remains the same, proving that its timeless appeal lies in its simplicity.
                        </div>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        Why Snake Game is Still Popular
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        <strong>Snake Game</strong> continues to be popular because of its easy-to-learn mechanics and the challenge it offers. Its nostalgic value appeals to players who grew up with the game, while its quick and straightforward gameplay makes it accessible to new players. Additionally, with the rise of mobile gaming and the popularity of retro games, Snake has found new life in today’s gaming landscape.
                    </div>
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        Snake’s enduring popularity can be attributed to:
                        <br />
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                <strong>Simplicity:</strong> The game doesn’t require complex controls or strategies, making it perfect for casual gaming.
                            </li>
                            <li>
                                <strong>Challenge:</strong> As the snake grows, the difficulty increases, providing a rewarding experience for players.
                            </li>
                            <li>
                                <strong>Nostalgia:</strong> For many, Snake brings back memories of early mobile gaming, creating a connection that newer games can’t replicate.
                            </li>
                        </ul>
                        <br />
                        <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                            Snake Game is a timeless classic that has made a lasting impact on gaming history. Its simplicity, accessibility, and challenging nature have allowed it to remain relevant for over four decades. Whether you’re playing an old-school version on a Nokia phone or a modern adaptation online, Snake Game continues to offer a fun, nostalgic, and rewarding gaming experience.
                        </div>
                        <br />
                        <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                            For anyone looking to relive the past or discover a simple yet addictive game, Snake Game remains an excellent choice. Explore the many versions available today and challenge yourself to become the ultimate snake master!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}