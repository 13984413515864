import { useEffect } from "react";

export default function DMCA() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'DMCA Policy | Snake Game';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Snake Game DMCA Page - We comply with DMCA law and protect intellectual property rights. See our guide to submitting a takedown request for infringing content here.");
        }
        return () => {
            document.title = 'Snake - Play Snake Game Online Unblocked🐍 PLAY NOW!';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Take on the Snake Game challenge, an arcade classic that improves focus and reflexes. Play Snake Computer Game online or offline and enjoy hours of addictive, skill-based fun!');
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-460px)] lg:min-h-[calc(100vh-390px)] text-white">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        DMCA Policy
                    </h1>
                    <br />
                    <span>
                        Welcome to the Snake Game! We respect intellectual property rights and are committed to complying with the Digital Millennium Copyright Act (DMCA) and other intellectual property laws.
                    </span>
                    <br />
                    <br />
                    <div>
                        <span>
                            If you are a copyright owner or an authorized representative of a copyright owner and believe that certain content on our website infringes your copyright, please submit a DMCA takedown notice so we can review and address your request.
                        </span>
                    </div>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        How to Submit a Copyright Infringement Notice
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        To help us accurately and quickly process your request, please provide the following information in your infringement notice:
                        <br />
                        <br />
                        <ul className="numbered-list">
                            <li>
                                <strong>Contact Information:</strong>  Your name, email address, and phone number so we can reach you if necessary.
                            </li>
                            <li>
                                <strong>Description of the Infringed Work:</strong> A detailed description of the work or material you believe is being infringed.
                            </li>
                            <li>
                                <strong>Link to Infringing Content:</strong> The URL or specific information to help us locate the infringing content on our website.
                            </li>
                            <li>
                                <strong>Good Faith Statement:</strong> A statement asserting that you have a legal basis to represent the copyright owner and that the information provided in the notice is accurate, under penalty of perjury.
                            </li>
                            <li>
                                <strong>Electronic or Physical Signature:</strong> Your signature or the signature of your authorized representative.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-lg lg:text-base text-base">
                        Submit the Infringement Notice
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        Please send your notice via email to: <a href="mailto:support@snakegame.games" className=" text-blue-500">{`support@snakegame.games`}</a>
                    </div>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        We will review all valid requests and take appropriate action to remove or disable access to the infringing content, if deemed necessary.
                    </div>
                    <br />
                    <div class="des-game  2xl:text-base lg:text-[14px] text-[14px]">
                        <strong>Note:</strong> Any notice that does not comply with these requirements may be deemed invalid. Additionally, please be aware that making false or inaccurate claims of copyright infringement may lead to legal consequences under the law.
                    </div>
                </div>
            </div>
        </div>
    )
}